import { useTranslation } from 'react-i18next';
import { useEffect, useContext, useState, useRef } from 'react';
import { NavContext } from '@/components/NavContext';
import { ArrowLeft } from '@teo/components/icons';
import { PageLayout } from '@/components/PageLayout';
import { LessonTreeEdit } from '@/components/LessonTree/LessonTreeEdit';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { Button, IconButton, Spinner } from '@teo/components';
import { useGet } from '@/query/fetchHooks';
import { useParams, useNavigate } from 'react-router-dom';
import { LessonView } from '@/components/LessonView';
import { useMediaQuery } from 'react-responsive';
import LessonHeader from '../../Learn/Courses/Lessons/LessonHeader';
import { PreviewTree } from './PreviewTree';
import { Portal } from '@/components/Portal';
import i18n from 'i18next';
import { parseQueryString, newUid } from '@/util';
import { ExtraInfoLesson } from '../../Learn/Courses/Lessons/ExtraInfoLesson';
import { ImageZoom } from '@/components/ImageZoom';
import { clsx } from 'clsx';
import { config } from '@/config';
import { axios } from '@/axios';

function PreviewCourse({
  showAnswers = false,
  instruction,
}: {
  showAnswers?: boolean;
  instruction?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNavDisabled } = useContext(NavContext);
  const { coursecontentId, courseId, lessonId } = useParams();
  const { auth, setAuth } = useContext(AuthContext);
  const userId = auth?.user?.id;
  const authUser = auth?.user;
  const [activeNode, setActiveNode] = useState<any>(null);
  const [hideContent, setHideContent] = useState<boolean>(false);
  const [nrOfPages, setNrOfPages] = useState(1);
  const [page, setPage] = useState(0);
  const [hasWidgets, setHasWidgets] = useState<boolean>(false);
  const [viewMobile, setViewMobile] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [allExtraInfo, setAllExtraInfo] = useState(null);
  const [rootCourseId, setRootCourseId] = useState(null);
  const [allDirectories, setAllDirectories] = useState(null);

  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [extraInfoFullScreen, setExtraInfoFullScreen] = useState(false);
  const [activeExtraInfo, setActiveExtraInfo] = useState(null);
  const [lengthExtraInfo, setLengthExtraInfo] = useState(0);
  const [imageZoom, setImageZoom] = useState(false);
  const [nextLesson, setNextLesson] = useState<any>(null);
  const [previousLesson, setPreviousLesson] = useState<any>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const [course, setCourse] = useState<any>(null);

  const queries = parseQueryString(document.location.search) as {
    [key: string]: string;
  };

  const role = Math.max(
    authUser?.role || 0,
    authUser?.activeOrganisationRole || 0
  );
  if (role <= 20) showAnswers = false;

  let basePath: any;
  if (courseId) {
    basePath = window.location.pathname.slice(
      0,
      window.location.pathname.lastIndexOf('/less')
    );
  } else {
    basePath = window.location.pathname.slice(
      0,
      window.location.pathname.lastIndexOf('/p')
    );
  }

  if (queries['returnUrl']) {
    basePath = decodeURIComponent(queries['returnUrl']);
  }

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const iSsmallLaptop = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  useEffect(() => {
    if (instruction && activeNode) {
      if (activeNode?.lessonId || activeNode?.assetId) {
        axios.put(`${config.backend}/follow/${courseId}/${userId}`, {
          courseId: courseId,
          currentLessonId: activeNode.lessonId || activeNode?.assetId,
          userId: userId,
        });
      }
    }
  }, [activeNode]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const viewVersion: any = queryParams.get('view');
    if (viewVersion == 'mobile') {
      setViewMobile(true);
      setNavDisabled(true);
      return () => setNavDisabled(false);
    }
  }, []);

  useEffect(() => {
    setNavDisabled(true);
    return () => setNavDisabled(false);
  }, []);

  useEffect(() => {
    if (showExtraInfo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showExtraInfo]);

  useEffect(() => {
    hideContent && !viewMobile && setActiveNode(null);
  }, [hideContent]);

  useGet(`/coursedetail/${courseId}`, {
    enabled: !!courseId,
    onSuccess: (result: any) => {
      setCourse(result?.data || {});
    },
  });

  return (
    <>
      {viewMobile && !isMobile && (
        <IconButton
          className="absolute left-5 top-5"
          Icon={ArrowLeft}
          variant="outline"
          onClick={() =>
            navigate(
              `${basePath}?les=${activeNode?.usesId ? activeNode?.usesId : 0}`
            )
          }
        />
      )}
      <div
        className={`flex min-h-screen flex-col ${
          viewMobile &&
          'mx-auto !my-5 w-full max-w-sm overflow-hidden rounded-lg border border-grey-02'
        }`}
      >
        <Portal root="head">
          <meta name="export-placeholder" content="" />
        </Portal>
        <LessonHeader
          authUser={authUser}
          setAuth={setAuth}
          userId={userId}
          coursePath={
            showAnswers
              ? `/${i18n.language}/follow-up/${authUser?.activeOrganisationId}/courses/${coursecontentId}`
              : basePath
          }
          setHideContent={setHideContent}
          hideContent={hideContent}
          activeLesson={activeNode}
          couresPreview={course?.courseType === 'instruction' ? false : true}
          activeNode={activeNode}
          setActiveNode={setActiveNode}
          viewMobile={viewMobile}
          setShowExtraInfo={setShowExtraInfo}
          setExtraInfoFullScreen={setExtraInfoFullScreen}
          extraInfoFullScreen={extraInfoFullScreen}
          setActiveExtraInfo={setActiveExtraInfo}
          lengthExtraInfo={lengthExtraInfo}
          iSsmallLaptop={iSsmallLaptop}
        />
        <PageLayout>
          {/* {(!isMobile || (!activeNode && isMobile)) && ( */}
          <PageLayout.Sidebar
            className={`relative max-w-[350px] border-r border-solid border-grey-02 !p-0  ${
              (isMobile || viewMobile) && !hideContent ? '!hidden' : null
            }`}
          >
            <div className="sticky top-[80px] max-h-[calc(100vh_-_80px)] overflow-y-auto p-5 md:w-[272px] lg:w-[350px]">
              <PreviewTree
                courseId={courseId}
                coursecontentId={
                  courseId
                    ? (course?.coursecontentId as string)
                    : (coursecontentId as string)
                }
                lessonId={lessonId}
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                setHideContent={setHideContent}
                setAllExtraInfo={setAllExtraInfo}
                setRootCourseId={setRootCourseId}
                setAllDirectories={setAllDirectories}
                setNextLesson={setNextLesson}
                setPreviousLesson={setPreviousLesson}
                setIsLoaded={setIsLoaded}
              />
            </div>
          </PageLayout.Sidebar>
          {/* )}  */}
          <div
            className={`relative flex grow p-4 ${
              (isMobile || viewMobile) && hideContent ? '!hidden' : ''
            } ${activeNode?.onlyTheory ? 'onlyTheory' : ''} ${
              hasWidgets ? 'noWidgets' : ''
            } ${viewMobile && 'relative'}`}
          >
            {isLoaded && (
              <div className="absolute inset-0 z-[11] flex bg-white">
                <div className="m-auto">
                  <Spinner
                    ariaLabel="Loading spinner"
                    className="!h-16 !w-16 border-grey-08"
                  />
                </div>
              </div>
            )}

            <div className="fr-view mx-auto w-full max-w-2xl">
              {activeNode && (
                <>
                  {activeNode?.lessonId && (
                    <LessonView
                      showAnswers={showAnswers}
                      pageBreak={!queries['noPageBreak']}
                      node={activeNode}
                      page={page}
                      setSubmitFn={() => {
                        const widgets =
                          document?.querySelectorAll('.widget_practical');
                        if (widgets?.length > 0) {
                          setHasWidgets(true);
                        } else {
                          setHasWidgets(false);
                        }
                      }}
                      setNrOfPages={(nrOfPages: number) => {
                        setPage(0);
                        setNrOfPages(nrOfPages);
                      }}
                      setIsLoaded={setIsLoaded}
                    ></LessonView>
                  )}
                  {activeNode?.assetId && activeNode?.assetPath && (
                    <div className="flex w-full items-start p-3">
                      {activeNode?.assetType == 'image' ? (
                        <>
                          <ImageZoom
                            activeExtraInfo={activeNode}
                            extraInfoFullScreen={true}
                            setImageZoom={setImageZoom}
                            imageZoom={imageZoom}
                            onLoad={() => {
                              setLoaded(true);
                              setIsLoaded(false);
                            }}
                          />
                          <div className={clsx(loaded && 'page-loaded')}></div>
                        </>
                      ) : (
                        <div id={`${newUid(20)}_embed`} className="mx-auto">
                          <iframe
                            id={`${newUid(20)}_placeholder`}
                            width="640"
                            height="360"
                            className=""
                            src={activeNode?.assetPath}
                            onLoad={() => {
                              setLoaded(true);
                              setIsLoaded(false);
                            }}
                          ></iframe>
                          <div className={clsx(loaded && 'page-loaded')}></div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              {/* {!activeNode?.assetId && ( */}
              <div className="teo_submit_row mt-6 flex justify-between">
                {(page == 0 || activeNode?.assetId) && previousLesson && (
                  <Button
                    className="mr-auto"
                    onClick={() => {
                      setIsLoaded(true);
                      setActiveNode(previousLesson);
                    }}
                    variant="secondary"
                  >
                    {t('pages.course_landing.previous')}
                  </Button>
                )}
                {page > 0 && !activeNode?.assetId && (
                  <Button
                    className="mr-auto"
                    name="previous_page"
                    onClick={async () => {
                      setPage(page - 1);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t('pages.course_landing.previous_page')}
                  </Button>
                )}
                {page < nrOfPages - 1 && !activeNode?.assetId && (
                  <Button
                    name="next_page"
                    onClick={async () => {
                      setPage(page + 1);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t('pages.course_landing.next_page')}
                  </Button>
                )}
                {((!hasWidgets && page == nrOfPages - 1) ||
                  activeNode?.assetId) &&
                  nextLesson && (
                    <Button
                      name="next_lesson"
                      onClick={() => {
                        setIsLoaded(true);
                        setActiveNode(nextLesson);
                      }}
                      className="ml-auto"
                    >
                      {t('pages.course_landing.next')}
                    </Button>
                  )}
                {hasWidgets && !showAnswers && !activeNode?.assetId && (
                  <Button
                    className="ml-auto"
                    name="submit"
                    onClick={() => {
                      setIsLoaded(true);
                      setActiveNode(nextLesson);
                    }}
                  >
                    {t('pages.course_landing.submit')}
                  </Button>
                )}
              </div>
              {/* )} */}
            </div>
            {!activeNode?.assetId && (
              <div className="">
                <ExtraInfoLesson
                  node={activeNode}
                  course={null}
                  rootCourseId={rootCourseId}
                  allExtraInfo={allExtraInfo}
                  allDirectories={allDirectories}
                  setShowExtraInfo={setShowExtraInfo}
                  showExtraInfo={showExtraInfo}
                  setExtraInfoFullScreen={setExtraInfoFullScreen}
                  extraInfoFullScreen={extraInfoFullScreen}
                  setActiveExtraInfo={setActiveExtraInfo}
                  activeExtraInfo={activeExtraInfo}
                  setLengthExtraInfo={setLengthExtraInfo}
                  lengthExtraInfo={lengthExtraInfo}
                  preview={true}
                  iSsmallLaptop={iSsmallLaptop}
                  isMobile={isMobile}
                  viewMobile={viewMobile}
                />
              </div>
            )}
          </div>
        </PageLayout>
      </div>
    </>
  );
}

export default PreviewCourse;
