import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { useGet } from '@/query/fetchHooks';
import { useTranslation } from 'react-i18next';
import { Button, Tag, Spinner } from '@teo/components';
import { Certificate } from '@teo/components/icons';
import { parseTypeCourse, downloadPDFLink } from '@/util';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import i18n from 'i18next';
import { publicAxios } from '@/axios';
import { config } from '@/config';
import { PaginationAnything } from '@/components/DataTable/PaginationAnything';

export const AssignedCertificates = ({ userId }: any) => {
  const { t } = useTranslation();

  const { auth } = useContext(AuthContext);
  const { setShowNotification } = useContext(NotificationContext);
  const authUser = auth?.user;

  const [loadCourses, setLoadCourses] = useState<any>(true);

  const downloadCertificate = async (courseId: any) => {
    const promises = [];
    const link = `${config.teoreport}/report/?lang=${i18n.language}&jwt=${auth?.token}`;
    promises.push(
      publicAxios.post(link, {
        download: true,
        lang: i18n.language,
        userId: userId,
        formType: 'certificate',
        courseId: courseId,
        organisationId: authUser?.activeOrganisationId,
      })
    );

    Promise.allSettled(promises).then((results: any) => {
      results[0]?.value?.data?.link &&
        downloadPDFLink(results[0]?.value?.data?.link);
    });
    setShowNotification({
      message: t('pages.report_page.report_download_message'),
      delay: 5000,
    });
  };

  return (
    <>
      {loadCourses && (
        <div className="mx-auto flex justify-center py-20 px-10">
          <Spinner
            ariaLabel="Loading spinner"
            className="!h-10 !w-10 border-grey-05"
          />
        </div>
      )}
      <PaginationAnything
        className={`flex w-full flex-col gap-3 ${
          loadCourses === true && ' hidden '
        }`}
        fetch={useGet.bind(null, `/mycourses`, {
          scope: 'nothidden,passed',
          userId: userId,
          organisationId:
            authUser?.activeOrganisationId + ',OR,organisationId=null',
          status: 'done',
          emailCertificate: true,
          onSuccess: (result: any) => {
            setLoadCourses(false);
          },
        })}
        defaultSort={[{ id: '-createdAt' }]}
        empty={{
          template: 2,
          loadContent: loadCourses,
          text: t('pages.manage_certificates.certificates_empty'),
        }}
        page={0}
        pageSize={8}
        filter={''}
        render={(course: any) => {
          const courseType = parseTypeCourse(course.courseType);
          const formattedType = courseType
            ? courseType[0].toUpperCase() + courseType.slice(1)
            : '';
          return (
            <div
              key={course?.id}
              className="flex flex-wrap items-center gap-3 rounded-lg border p-3 sm:flex-nowrap"
            >
              <div className="rounded-lg border border-secondary-02 bg-secondary-01">
                <Certificate className="m-2 h-6 w-6 min-w-6 text-secondary-04 sm:m-4 sm:h-8 sm:w-8 sm:min-w-8" />
              </div>
              <div className="flex flex-1 flex-col">
                <h4 className="text-base font-medium">
                  {course?.courseName || course?.name || ''}
                </h4>
                <div>
                  <Tag title={formattedType} />
                </div>
              </div>
              <div className="w-full sm:w-auto">
                <Button
                  onClick={() => downloadCertificate(course?.id)}
                  variant="outline"
                >
                  {t('pages.manage_certificates.download_certificate')}
                </Button>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
