import {
  IconButton,
  Modal,
  PersonAvatar,
  Dropdown,
  Switch,
  Label,
  Button,
} from '@teo/components';
import React, { useState, useContext, useEffect, useReducer } from 'react';
import { ChevronDown } from '@teo/components/icons';
import { useTranslation } from 'react-i18next';
import { useGet } from '@/query/fetchHooks';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { DataTable } from '@/components/DataTable/DataTable';
import { UserInvite } from '@/components/UserInvite/UserInvite';
import { newUid, nthIndexOf } from '@/util';
import { config } from '@/config';
import { axios } from '@/axios';
import { useNavigate } from 'react-router-dom';
import LibraryAddOrganizations from '@/pages/Protected/Create/PublishCourse/LibraryAddOrganizations';
import { OrganisationContext } from '@/components/Contexts/OrganisationContext';

export const ContentCreatorInviteModal = ({
  onClose,
  publication,
  refetchCreatorResults,
  setPublication,
  contentCreators,
  onNavigate,
  publicationType,
}: any) => {
  const { t } = useTranslation();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [refetchTable, setRefetchTable] = useState<string>(newUid(20));
  const [value, setValue] = useState<any>([]);
  const [oneOwner, setOneOwner] = useState<any>(true);
  const [initialContentOrganizations, setinitialContentOrganizations] =
    useState<any>([]);

  const [isProtected, setIsProtected] = useState(
    publication?.publicationAccess === 'protected'
  );

  const initialState = {
    isProtected: publication?.publicationAccess === 'protected',
    users: [],
    allOrganizations: publication?.publicationAccess === 'public',
    organizations: [],
  };

  const [shareState, setShareState] = useReducer(
    (state: any, updates: any) => ({ ...state, ...updates }),
    initialState
  );

  const { myOrganisations } = useContext(OrganisationContext);
  let isAdminSomewhereElse = false,
    adminOrganisations = [];
  try {
    adminOrganisations = myOrganisations.filter(
      (org: any) =>
        org.id !== auth?.user?.activeOrganisationId && org.role >= 90
    );
    isAdminSomewhereElse = adminOrganisations.length > 0;
  } catch (e) {
    console.error(e);
  }

  const orgs = useGet(`/coursecontentorganisationdetails`, {
    coursecontentId: publication?.id,
    onSuccess: (result: any) => {
      setinitialContentOrganizations(
        result?.data?.map((item: any) => ({
          ...item,
          key: item.id,
        })) || []
      );
    },
  });

  useEffect(() => {
    if (orgs?.data) {
      const data =
        (orgs?.data?.data as any[]).map((item: any) => ({
          ...item,
          key: item.id,
        })) || [];
      setShareState({ organizations: data });
    }
  }, [orgs?.data]);

  const activeOrganisationId = auth?.user?.activeOrganisationId;

  // console.log(contentCreators);

  useEffect(() => {
    if (contentCreators) {
      const allOwner = contentCreators?.filter((user: any) => user?.role >= 90);
      setOneOwner(allOwner?.length <= 1);
    }
  }, [contentCreators]);

  // console.log((usr.role >= 90 && oneOwner) ||
  // usr.role >= 70);

  const handleInviteUser = (values: any[]) => {
    values.map(async (item: any) => {
      if (item.newUser) {
        const newUser = await axios.post(
          `${config.backend}/invite/provision?lang=${auth?.user?.lang}`,
          {
            organisationId: activeOrganisationId,
            coursecontentId: publication.id,
            email: item.label,
            role: 30,
          }
        );
        item.key = newUser?.data?.id;
      }

      axios
        .post(`${config.backend}/ownedcourses`, {
          coursecontentId: publication.id,
          userId: item.key,
          role: 70,
        })
        .then(() => {
          setRefetchTable(newUid(15));
          setValue([]);
          refetchCreatorResults();
        });
    });
  };

  const handleSave = () => {
    const added = [];
    const deleted = [];
    const changed = [];

    const oldMap: any = new Map(
      initialContentOrganizations.map((item: any) => [item.key, item])
    );
    const newMap: any = new Map(
      shareState.organizations.map((item: any) => [item.key, item])
    );

    for (const [id, newItem] of newMap.entries()) {
      if (!oldMap.has(id)) {
        added.push(newItem);
      } else {
        if (oldMap.get(id).role !== newItem.role) {
          changed.push(newItem);
        }
      }
    }
    for (const [id, oldItem] of oldMap.entries()) {
      if (!newMap.has(id)) {
        deleted.push(oldItem);
      }
    }

    added.forEach((org: any) => {
      axios.post(`${config.backend}/membercoursecontents`, {
        coursecontentId: publication?.id,
        organisationId: org.id,
        role: org.role,
      });
    });

    deleted.forEach((org: any) => {
      axios.delete(
        `${config.backend}/membercoursecontent/${org.id}/${publication?.id}`,
        {}
      );
    });

    changed.forEach((org: any) => {
      axios.put(
        `${config.backend}/membercoursecontent/${org.id}/${publication?.id}`,
        {
          role: org.role,
        }
      );
    });

    onClose();
  };

  const handleProtected = () => {
    const newProtected = !isProtected;
    let pubAccess: any;

    if (newProtected) {
      pubAccess = 'protected';
    }
    if (!newProtected) {
      pubAccess = 'private';
    }

    setPublication((prev: any) => ({
      ...prev,
      publicationAccess: pubAccess,
    }));

    axios.put(`${config.backend}/coursecontent/${publication?.id}`, {
      publicationAccess: pubAccess,
    });
  };

  return (
    <Modal isOpen={true}>
      <Modal.Header onClose={() => onClose()}>
        <p className="text-sm font-semibold">
          {t('pages.create_edit_course.share_modal_title')}
        </p>
      </Modal.Header>
      {/* <Modal.Body> */}
      <div className="border-b border-grey-02 p-6">
        <>
          <div className="text-sm font-normal">
            <Label htmlFor="invite_author">
              <p className="text-sm font-semibold">
                {t('pages.create_edit_course.multiselect_label')}
              </p>
            </Label>
            <div className="mt-4 text-[#7d7d81]">
              {t(
                `pages.create_edit_course.type_${publicationType}.add_authors_desc`
              )}
            </div>
            <UserInvite
              publicationType={publicationType}
              fetch={useGet.bind(null, '/invitecontentcreators', {
                coursecontentId: publication.id,
                organisationId: activeOrganisationId,
              })}
              inviteButtonText={t('pages.create_edit_course.invite_button')}
              value={value}
              setValue={setValue}
              handleAddUserToList={handleInviteUser}
            />
          </div>
          <DataTable
            pageSize={5}
            key={refetchTable}
            defaultSort={[{ id: 'username', desc: false }]}
            fetch={useGet.bind(null, '/contentcreators', {
              coursecontentId: publication.id,
            })}
            columns={[
              {
                className: 'col-start-1 col-end-6 row-start-1',
                title: t('columns.authors'),
                id: 'authors',
                render: (usr: any) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PersonAvatar
                      inline
                      id={usr.id}
                      name={usr.username}
                      imageSrc={usr.picture}
                    ></PersonAvatar>
                    <div className="flex flex-col">
                      <span className="font-medium">{usr.username}</span>
                      <span className="text-xs font-normal text-[#7D7D81]">
                        {usr.email}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                className: 'col-start-6 col-end-10 row-start-1 justify-end',
                title: '',
                id: 'authors_role',
                align: 'right',
                render: (usr: any) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Dropdown>
                        <Dropdown.Trigger className="relative z-[2] flex items-center">
                          {usr.role >= 90 ? (
                            <p>
                              {t('pages.create_edit_course.make_owner_button')}
                            </p>
                          ) : (
                            <p>
                              {t('pages.create_edit_course.may_edit_button')}
                            </p>
                          )}
                          <IconButton Icon={ChevronDown} variant="ghost" />
                        </Dropdown.Trigger>
                        <Dropdown.Content>
                          <Dropdown.Actions>
                            <Dropdown.Button
                              className={`${
                                ((usr.role >= 90 && oneOwner) ||
                                  usr.role === 70) &&
                                'pointer-events-none text-grey-04'
                              }`}
                              onClick={() => {
                                axios
                                  .put(
                                    `${config.backend}/ownedcourse/${publication.id}/${usr.id}`,
                                    {
                                      role: 70,
                                    }
                                  )
                                  .then(() => {
                                    refetchCreatorResults();
                                    setRefetchTable(newUid(20));
                                  });
                              }}
                              disabled={
                                (usr.role >= 90 && oneOwner) || usr.role === 70
                              }
                            >
                              {t('pages.create_edit_course.may_edit_button')}
                            </Dropdown.Button>
                            <Dropdown.Button
                              className={`${
                                usr.role >= 90 &&
                                'pointer-events-none text-grey-04'
                              }`}
                              disabled={usr.role >= 90}
                              onClick={() => {
                                axios
                                  .put(
                                    `${config.backend}/ownedcourse/${publication.id}/${usr.id}`,
                                    {
                                      role: 90,
                                    }
                                  )
                                  .then(() => {
                                    refetchCreatorResults();
                                    setRefetchTable(newUid(20));
                                  });
                                axios
                                  .put(
                                    `${config.backend}/coursecontent/${usr.coursecontentId}`,
                                    {
                                      authorId: usr.userId,
                                    }
                                  )
                                  .then(() => {
                                    setRefetchTable(newUid(20));
                                    refetchCreatorResults();
                                  });
                              }}
                            >
                              {t('pages.create_edit_course.make_owner_button')}
                            </Dropdown.Button>

                            <Dropdown.Button
                              className={`${
                                usr.role >= 90 && oneOwner
                                  ? 'pointer-events-none text-grey-04'
                                  : 'text-[#FC6232]'
                              }`}
                              disabled={usr.role >= 90 && oneOwner}
                              onClick={() => {
                                axios
                                  .delete(
                                    `${config.backend}/ownedcourse/${publication.id}/${usr.id}`
                                  )
                                  .then(() => {
                                    if (
                                      auth?.user?.id === usr.id &&
                                      (auth?.user?.activeOrganisationRole ||
                                        0) < 90 &&
                                      publication?.publicationAccess ===
                                        'private'
                                    ) {
                                      const basePath =
                                        window.location.pathname.slice(
                                          0,
                                          nthIndexOf(
                                            window.location.pathname,
                                            '/',
                                            3
                                          )
                                        );
                                      onNavigate();
                                      navigate(basePath);
                                    }
                                    setRefetchTable(newUid(20));
                                    refetchCreatorResults();
                                  });
                              }}
                            >
                              <p>
                                {t('pages.create_edit_course.delete_button')}
                              </p>
                            </Dropdown.Button>
                          </Dropdown.Actions>
                        </Dropdown.Content>
                      </Dropdown>
                    </div>
                  );
                },
              },
            ]}
          />
          <div className="mt-6 flex flex-col">
            <Label htmlFor="invite_author">
              <p className="text-sm font-semibold">
                {t('pages.create_edit_course.my_organisation')}
              </p>
            </Label>
            <div className="flex flex-col gap-4 rounded-lg border border-grey-02 p-4">
              <div className="flex flex-row items-center gap-4">
                <Switch
                  checked={isProtected}
                  onChange={() => {
                    setIsProtected(!isProtected);
                    handleProtected();
                  }}
                />
                <div>
                  <h4 className="pb-2 font-normal">
                    {t(
                      'pages.create_edit_course.share_details.switch_organizations'
                    )}
                    {auth?.user?.organisationName}
                  </h4>
                  <p className="text-xs text-grey-05">
                    {t(
                      `pages.create_edit_course.type_${publicationType}.share_details.switch_organizations_description`
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {((auth?.user?.role || 0) >= 90 || isAdminSomewhereElse) && (
            <div className="mb-2 mt-6">
              <p className="text-sm font-semibold">
                {t(`pages.create_edit_course.other_organisations`)}
              </p>
              <LibraryAddOrganizations
                publication={publication}
                state={shareState}
                updateState={setShareState}
                auth={auth}
                getOrg={'/coursecontentorganisationdetails'}
                payload={{ coursecontentId: publication?.id }}
                publicationType={publicationType}
              />
            </div>
          )}
        </>
      </div>
      <Modal.Footer cancelButtonLabel={t('button.cancel')} onCancel={onClose}>
        <div>
          <Button onClick={() => handleSave()}>{t('button.save')}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
