import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Label,
  InputText,
  InputDate,
  InputSelect,
} from '@teo/components';
import { useState, useContext } from 'react';
import ColorPicker from '@/components/ColorPicker';
import IconPicker from '@/components/IconPicker';
import { config } from '@/config';
import { Userwithorganisationwithidps } from '@/models';
import { AuthContext } from '@/components/Contexts/AuthContext';
import { downloadLink } from '@/util';
import i18n from 'i18next';
import { useGet } from '@/query/fetchHooks';
import { PaginationMultiSelect } from '@/components/PaginationMultiSelect';
import { NotificationContext } from '@/components/Contexts/NotificationContext';
import { axios, publicAxios } from '@/axios';

function ReportModal({ onClose }: any) {
  const { t } = useTranslation();
  const { setShowNotification } = useContext(NotificationContext);

  const [name, setName] = useState('');

  const [from, setFrom] = useState<Date | undefined>();
  const [to, setTo] = useState<Date | undefined>(new Date());

  const [members, setMembers] = useState<any[]>([]);
  const [selectTeams, setSelectTeams] = useState<any[]>([]);
  const [courses, setCourses] = useState<any[]>([]);

  const { auth } = useContext(AuthContext);
  const authUser = auth?.user as Userwithorganisationwithidps;

  const reportOptions = [
    {
      value: 'csv',
      label: t('pages.report.overview'),
    },
    {
      value: 'detailed',
      label: t('pages.report.course_overview_detailed'),
    },
    {
      value: 'overview',
      label: t('pages.report.course_overview'),
    },
  ];

  const reportTeamList = [
    {
      value: 'studentOverview',
      label: t('report.studentOverview'),
    },
    {
      value: 'courseOverview',
      label: t('report.courseOverview'),
    },
    {
      value: 'courseCsv',
      label: t('report.courseCsv'),
    },
    {
      value: 'coursedetailsCsv',
      label: t('report.coursedetailsCsv'),
    },
    {
      value: 'usersCsv',
      label: t('report.usersCsv'),
    },
  ];

  const [reportType, setReportType] = useState(
    authUser?.activeOrganisationRole !== 40
      ? reportOptions[0]
      : reportTeamList[0]
  );

  const [myTeams, setMyTeams] = useState<any>(null);

  useGet(`/myteams`, {
    enabled: !(authUser?.activeOrganisationRole !== 40),
    userId: (authUser?.role || 0) >= 90 ? undefined : authUser?.id,
    organisationId: authUser?.activeOrganisationId,
    teamStatus: 'active',
    onSuccess: (results: any) => {
      setMyTeams(results?.data);
    },
  });

  const downloadReport = async () => {
    if (reportType?.value === 'csv') {
      const promises = [];
      const link = `${config.teoreport}/report/?lang=${i18n.language}&jwt=${auth?.token}`;
      promises.push(
        publicAxios.post(link, {
          download: true,
          lang: i18n.language,
          userId: members.map((member) => member.id),
          formType: 'courseCsv',
          organisationId: authUser?.activeOrganisationId,
        })
      );

      Promise.allSettled(promises).then((results: any) => {
        results[0]?.value?.data?.link &&
          downloadLink(results[0]?.value?.data?.link);
      });
      setShowNotification({
        message: t('pages.report_page.report_download_message'),
        delay: 5000,
      });
    } else if (reportType?.value === 'detailed') {
      let downloadPath;
      if (courses.length) {
        switch (courses[0].courseType) {
          case 'course':
            downloadPath = 'course';
            break;
          case 'leervacature':
            downloadPath = 'vacancy';
            break;
          case 'assessment':
            downloadPath = 'assessment';
            break;
          default:
            downloadPath = 'course';
        }
      } else {
        downloadPath = 'course';
      }
      const userIds = members.map((member) => member.id);
      const link = `${config.teoreport}/report`;

      axios
        .post(link, {
          userId: userIds.join(','),
          courseId: courses.map((course) => course.id).join(','),
          formId: 2147483647,
          lang: i18n.language,
          organisationId: authUser.activeOrganisationId,
        })
        .then((result: any) => {
          setShowNotification({
            message: t('pages.report.emailed') + ` (${result?.data?.email})`,
            delay: 20000,
          });
        });
    } else {
      const userIds = members.map((member) => member.id);
      const link = `${config.teoreport}/report/?lang=${i18n.language}&jwt=${auth?.token}`;

      publicAxios
        .post(link, {
          userId: userIds.join(','),
          courseId: courses.map((course) => course.id).join(','),
          formType: 'courseOverview',
          organisationId: authUser.activeOrganisationId,
          noBirth: true,
          noDiploma: true,
          noLessons: true,
        })
        .then((result: any) => {
          setShowNotification({
            message: t('pages.report.emailed') + ` (${result?.data?.email})`,
            delay: 20000,
          });
        });
    }
    onClose();
  };

  const downloadTeamReport = () => {
    const teamsId =
      selectTeams?.length > 0
        ? selectTeams?.map((team: any) => team.id)
        : myTeams?.map((team: any) => team.id);
    let overviewCur: any;
    if (reportType?.value === 'overviewCourse') {
      overviewCur = true;
    }
    const promises = [];
    const link = `${config.teoreport}/report/?lang=${i18n.language}&jwt=${auth?.token}`;
    promises.push(
      axios.post(link, {
        email: authUser.email,
        organisationId: authUser.activeOrganisationId,
        download: true,
        lang: i18n.language,
        teamIds: teamsId,
        endDate: null,
        startDate: null,
        formType:
          reportType?.value === 'overviewCourse'
            ? 'courseOverview'
            : reportType?.value,
        noBirth: overviewCur,
        noDiploma: overviewCur,
        noLessons: overviewCur,
      })
    );

    Promise.allSettled(promises).then((results: any) => {
      results[0]?.value?.data?.link &&
        downloadLink(results[0]?.value?.data?.link);
    });

    setShowNotification({
      message: t('pages.report_page.report_download_message'),
      delay: 5000,
    });
    // .then((result: any) => {
    //   setShowNotification({
    //     message: t('pages.report_page.report_download_message'),
    //     delay: 5000,
    //   });
    // });
    onClose();
  };

  return (
    <div className="bg-white">
      <Modal isOpen={true}>
        <Modal.Header onClose={onClose}>
          <h2
            className="text-sm font-semibold"
            data-testid="addCategoriesHeader"
          >
            {t('pages.report.title')}
          </h2>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-base font-semibold">
            {t('pages.report.subtitle')}
          </h1>
          <p className="mt-2 text-sm font-normal text-[#7d7d81]">
            {t('pages.report.description')}
          </p>

          <form
            className="w-full max-w-[440px] flex-col max-md:h-full max-md:overflow-hidden"
            data-testid="addCategoriesForm"
          >
            <div
              className={` ${
                authUser?.activeOrganisationRole !== 40
                  ? 'w-[280px]'
                  : 'w-[400px]'
              }`}
            >
              <Label htmlFor="courseLang">
                <p className="mt-6 text-sm font-semibold">
                  {t('pages.report.type_select')}
                </p>
              </Label>
              <div id="courseLang">
                <InputSelect<any>
                  onChange={(newReportType) => {
                    setReportType(newReportType);
                  }}
                  value={reportType}
                  options={
                    authUser?.activeOrganisationRole !== 40
                      ? reportOptions
                      : reportTeamList
                  }
                >
                  {(options) => <span>{options?.label}</span>}
                </InputSelect>
              </div>
            </div>
            {authUser?.activeOrganisationRole !== 40 && (
              <>
                <div>
                  <Label htmlFor="members">
                    <p className="mt-6 text-sm font-semibold">
                      {t('pages.report.select_users')} ({t('forms.optional')})
                    </p>
                  </Label>
                  <PaginationMultiSelect
                    id="members"
                    value={members}
                    onChange={(value: any) => {
                      setMembers(value);
                    }}
                    keyFn={(row: any) => row?.id}
                    fetch={useGet.bind(
                      null,
                      `/memberdetails?lang=${i18n.language}&organisationId=${auth?.user?.activeOrganisationId}`
                    )}
                    defaultSort={[{ id: 'username', desc: false }]}
                    render={(row: any) => {
                      return row?.username;
                    }}
                  />
                </div>
                {(reportType?.value === 'detailed' ||
                  reportType?.value === 'overview') && (
                  <div>
                    <Label htmlFor="courses">
                      <p className="mt-6 text-sm font-semibold">
                        {t('pages.report.select_courses')} (
                        {t('forms.optional')})
                      </p>
                    </Label>
                    <PaginationMultiSelect
                      id="courses"
                      value={courses}
                      onChange={(value: any) => {
                        setCourses(value);
                      }}
                      keyFn={(row: any) => row?.id}
                      fetch={useGet.bind(
                        null,
                        `/membercoursedetails?organisationId=${auth?.user?.activeOrganisationId}`
                      )}
                      defaultSort={[{ id: 'courseName', desc: false }]}
                      render={(row: any) => {
                        return row?.courseName?.length > 0
                          ? row?.courseName
                          : row?.name;
                      }}
                    />
                  </div>
                )}
              </>
            )}

            {!(authUser?.activeOrganisationRole !== 40) && myTeams && (
              <div>
                <Label htmlFor="members">
                  <p className="mt-6 text-sm font-semibold">
                    {t('pages.report.select_teams')} ({t('forms.optional')})
                  </p>
                </Label>
                <PaginationMultiSelect
                  id="teams"
                  value={selectTeams}
                  onChange={(value: any) => {
                    setSelectTeams(value);
                  }}
                  keyFn={(row: any) => row?.id}
                  fetch={useGet.bind(
                    null,
                    `/myteams?userId=${authUser?.id}&organisationId=${auth?.user?.activeOrganisationId}&teamStatus=active`
                  )}
                  defaultSort={[{ id: 'teamName', desc: false }]}
                  render={(row: any) => {
                    return row?.teamName;
                  }}
                />
              </div>
            )}
          </form>
        </Modal.Body>

        <Modal.Footer
          cancelButtonLabel={t('pages.manage_categories.cancel_button')}
          onCancel={onClose}
        >
          <div data-testid="addCategoriesFooter">
            <Button
              type="button"
              disabled={!(authUser?.activeOrganisationRole !== 40) && !myTeams}
              onClick={() =>
                authUser?.activeOrganisationRole !== 40
                  ? downloadReport()
                  : downloadTeamReport()
              }
            >
              {t('pages.manage_categories.download')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReportModal;
