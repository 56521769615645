import { useState, useContext, useEffect, useRef } from 'react';
import LearnLesson from './LessonPage';
import { XAPIService } from '@/services/xapi.service';
import Cmi5, { LaunchParameters } from '@xapi/cmi5';
import {
  parseJwt,
  useStateAndRef,
  useTraceUpdate,
  parseQueryString,
} from '@/util';
import { NavContext } from '@/components/NavContext';
import { axios } from '@/axios';
import { config } from '@/config';
import { useNavigate, useMatches, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { encode } from '@/base64';
import XAPI from '@xapi/xapi';

//this component will login with the xapi info and then redirect
function Xapi() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const queries: any = parseQueryString(document.location.search);

    //console.log("queries", JSON.stringify(queries, null, 4))
    const token = queries.token;

    const simplified: any = {
      endpoint: queries.endpoint,
      actor: queries.actor,
      registration: queries.registration,
      activityId: queries.activityId || queries.activity_id,
      lang: i18n.language,
    };

    const tincanQueries = {
      auth: queries.auth,
      activity_id: queries.activity_id,
      enroll_id: queries.enroll_id,
      component_id: queries.component_id,
      lup_tincandata_id: queries.lup_tincandata_id,
      slt: queries.slt,
    };

    //catch some of the differences of the various platforms
    if (queries.fetch) {
      simplified.fetch = queries.fetch;
    }

    /*
    let queryString =
      '?' +
      Object.keys(simplified)
        .map((key) => {
          return `${key}=${simplified[key]}`;
        })
        .join('&');

    if (queries.auth) {
      queryString += `&auth=${queries.auth}`;
    }
    */

    let extraQueryString = '';
    if (!queries.fetch) {
      simplified.tincan = true;
      extraQueryString += `&tincan=true`;
    }

    //launch cmi5
    /*
    const cmi5 = new Cmi5(simplified);
    const launchParameters: LaunchParameters & { token?: string } =
      cmi5.getLaunchParameters();
    launchParameters.token = token;
    */

    axios
      .post(
        config.teologin + '/xapi',
        Object.assign({}, Object.assign({}, simplified, tincanQueries), {
          token: token,
        }),
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        const redirectUrl = new URL(window.location.href);
        //redirectUrl.search = queryString;

        const redirect_uri = encodeURIComponent(
          redirectUrl.toString() + extraQueryString
        ).replace('%2Fxapi', '%2Flesson');
        //console.log("redirect uri", redirect_uri)

        const logintoken = result.data.token; //possible invite token
        const path = `${config.backend}/auth?idp=local&lang=${i18n.language}&redirect_uri=${redirect_uri}&logintoken=${logintoken}`; //`s
        window.location.replace(path);
      })
      .catch((e) => {
        console.error(e);
        const error = {
          status: 'xapi_login_failed',
        };
        navigate(`/error?error=${encode(JSON.stringify(error))}`);
      });
  }, []);

  return <></>;
}

export default Xapi;
